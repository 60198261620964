import {CreditCard, GitHub, Gitlab, Home, HelpCircle, Trello, Share2} from 'react-feather';
import { SidebarItemsType } from '../../types/sidebar';
import AuditPLogo from "../../assets/icons/CustomSvg/AuditPage";
import SettingsIco from "../../assets/icons/CustomSvg/SettingsIco";
import Jira from "../../assets/icons/CustomSvg/Jira";
import Notion from "../../assets/icons/CustomSvg/Notion";

const dashboardSection = [
  {
    href: '/',
    icon: Home,
    title: 'common.default_pages.dashboard',
  },
  {
    href: '/app-connector',
    // href: '/new-app',
    icon: Share2,
    title: 'common.default_pages.app_connector',
  },
  {
    href: '/subscriptions',
    icon: CreditCard,
    title: 'common.default_pages.subscriptions',
  },
  // {
  //   href: '/monitor',
  //   icon: TrendingUp,
  //   title: 'common.default_pages.job_monitor',
  // },
  // {
  //   href: '/reports',
  //   icon: FileText,
  //   title: 'common.default_pages.reports',
  // },
  {
    href: '/audit',
    icon: AuditPLogo,
    title: 'common.default_pages.audit_trail',
  },
  {
    href: '/settings',
    icon: SettingsIco,
    title: 'common.default_pages.profile',
  },
  {
    href: 'contact',
    icon: HelpCircle,
    title: 'common.default_pages.support',
  },
] as SidebarItemsType[];

const integrationsSection = [
  {
    href: '/platform/dashboard/github',
    icon: GitHub,
    title: 'common.platforms.github',
    // children: [
    //   {
    //     href: '/platform/dashboard/github',
    //     icon: Shield,
    //     title: 'views.platform_pages.protection',
    //   },
    //   {
    //     href: '/platform/user-settings/github',
    //     icon: Sliders,
    //     title: 'views.platform_pages.configuration',
    //   },
    // ],
  },
  {
    href: '/platform/dashboard/trello',
    icon: Trello,
    title: 'common.platforms.trello',
  },
  {
    href: '/platform/dashboard/gitlab',
    icon: Gitlab,
    title: 'common.platforms.gitlab',
  },
  {
    href: '/platform/dashboard/jira',
    icon: Jira,
    title: 'common.platforms.jira'
  },
  {
    href: '/platform/dashboard/notion',
    icon: Notion,
    title: 'common.platforms.notion'
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: '',
    pages: dashboardSection,
  },
  {
    title: 'common.default_pages.integrations',
    pages: integrationsSection,
  },
];

export default navItems;
